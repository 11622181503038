import { Box, Typography, type SxProps } from '@mui/material'
import { spacing, Spinner } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'

export type LocalLoadingStateProps = {
  children?: ReactNode
  sx?: SxProps
  className?: string
}

export function LocalLoadingState({ children, sx = {}, className }: LocalLoadingStateProps): JSX.Element {
  return (
    <Box sx={{ textAlign: 'center', ...sx }} className={className}>
      <Spinner size="50" />
      <Typography variant="body1" sx={{ marginTop: spacing[70] }}>
        {children ? <p>{children}</p> : <></>}
      </Typography>
    </Box>
  )
}
